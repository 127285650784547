import React from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import * as Yup from "yup";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import Layout from "../layout/Layout";
import AccouintNav from "../components/account/account-nav";
import TextField from "../components/form/TextField";
import {
  emailRegExp,
  emailRegMsg,
  getFormError,
  requiredEmailMsg,
  requiredMsg,
} from "../utils/form";
import useGetAccountDetails from "../hooks/customer/useGetAccountDetails";
import { navigate } from "gatsby";

const validationSchema = object().shape({
  first_name: string().required(requiredMsg("first name")),
  last_name: string().required(requiredMsg("last name")),
  email: string()
    .matches(emailRegExp, emailRegMsg)
    .email(requiredEmailMsg())
    .required(requiredEmailMsg("Email Address")),
  password: string().min(7),
  confirm_password: Yup.string()
    .label("confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  phone: string().min(7),
});

const updateCustomer = (values) => {
  const customer = sessionStorage.getItem("customerId");
  const data = {
    id: customer.response.customer_id,
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    company: values.company_name,
    phone: values.phone,
    authentication: {
      force_password_reset: true,
      new_password: values.password,
    },
  };

  fetch(`/.netlify/functions/bigcommerce?endpoint=customers`, {
    method: "PUT",
    credentials: "same-origin",
    mode: "same-origin",
    body: JSON.stringify([data]),
  })
    .then((res) => navigate("/login"))
    .catch((error) => console.error(error));
};

const initialValues = {
  email: "",
  password: "",
  confirm_password: "",
  current_password: "",
  first_name: "",
  last_name: "",
  company_name: "",
  phone: "",
};

function AccountSettings({ location }) {
  const { customerInfo } = useGetAccountDetails();

  const savedValues = {
    email: customerInfo.email,
    first_name: customerInfo.first_name,
    last_name: customerInfo.last_name,
    company_name: customerInfo.company,
    phone: customerInfo.phone,
  };

  const formik = useFormik({
    initialValues: savedValues || initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateCustomer(values);
    },
  });
  
  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <BreadCrumbs location={location} />
          <h1 className="page-title">Account Settings</h1>
        </div>
      </div>
      <div className="container">
        <AccouintNav path={"account-settings"} />

        <form
          className="form create-account-form form-mid"
          onSubmit={formik.handleSubmit}
        >
          <div className="form-row form-row--half">
            <div className="form-field">
              <TextField
                id={"customer-first-name"}
                name={"first_name"}
                label={"First Name"}
                labelFor={"first-name"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.first_name}
                error={getFormError(formik.errors, "first_name")}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-last-name"}
                name={"last_name"}
                label={"Last Name"}
                labelFor={"last-name"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                error={getFormError(formik.errors, "last_name")}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-company-name"}
                name={"company_name"}
                label={"Company Name"}
                labelFor={"compnay-name"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.company_name}
                error={getFormError(formik.errors, "company_name")}
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-phone"}
                name={"phone"}
                label={"Phone Number"}
                labelFor={"phone-number"}
                type={"input"}
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={getFormError(formik.errors, "phone")}
              />
            </div>

            <div className="form-field">
              <TextField
                id="customer-email"
                label={"Email Address"}
                labelFor={"Email Address"}
                name={"email"}
                error={getFormError(formik.errors, "email")}
                type={"email"}
                onChange={formik.handleChange}
                value={formik.values.email}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id="customer-password"
                name={"password"}
                label={"Password"}
                htmlFor={"password"}
                type={"password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={getFormError(formik.errors, "password")}
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-confirm-password"}
                name={"confirm_password"}
                label={"Confirm Password"}
                labelFor={"confirm-password"}
                type={"password"}
                onChange={formik.handleChange}
                value={formik.values.confirm_password}
                error={getFormError(formik.errors, "confirm_password")}
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-current-password"}
                name={"current_password"}
                label={"Current Password"}
                labelFor={"current-password"}
                type={"password"}
                onChange={formik.handleChange}
                value={formik.values.current_password}
                error={getFormError(formik.errors, "current_password")}
              />
            </div>
          </div>

          <div className="form-actions text-center">
            <button type="submit" className="button">
              Save Setting
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AccountSettings;
