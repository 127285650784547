import { useState, useEffect } from "react";
import useSWR from "swr";
import Api from "../../services/Api";

function useGetAccountDetails() {
  const [customer, setCustomer] = useState();
  useEffect(() => {
    setCustomer(JSON.parse(sessionStorage.getItem("customerId")));
  }, []);

  const getKey = () => {
    return `${
      process.env.NETLIFY_API_BASE_URL
    }?endpoint=customers?id:in=${[
      customer.response.customer_id,
    ]}`;
  };

  const { data, error } = useSWR(() => getKey(), getAccountDetails);

  const isLoading = !data && !error;
  const customerInfo = data ? data[0] : {};

  return {
    isLoading,
    customerInfo,
  };
}

async function getAccountDetails(url) {
  const response = await Api.get(url);
  return response.data.data;
}

export default useGetAccountDetails;
